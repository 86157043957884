const axios = require('axios');
import {Capacitor} from "@capacitor/core";
import {App} from "@capacitor/app";
import {Dialog} from "@capacitor/dialog";
import store from '../store';

export async function checkForUpdate() {
    // Check if is running on app or web
    if (Capacitor.isNativePlatform()) {
        axios.get('https://distractieonline.voina.nl/v.json?time=' + (new Date().getTime())).then(resp => {
            let vInfo = resp.data;

            // Store vInfo for the whole app to use
            store.dispatch('updateVInfo', vInfo);

            App.getInfo().then((appInfo) => {
                const platform = Capacitor.getPlatform();

                // Check if ios or android
                if (platform === 'android') {
                    showDialog(appInfo, vInfo.android);
                } else if (platform === 'ios') {
                    showDialog(appInfo, vInfo.ios);
                }
            });
        }).catch(reason => (
            Dialog.alert({
                title: 'ERROR:',
                message: reason.toString(),
            })
        ))
    }
}

function showDialog(appInfo, vInfo) {
    if (checkVersion(appInfo.version, vInfo.minimum) === -1) {
        // Mandatory update
        Dialog.alert({
            title: 'Update required!',
            message: 'Please update the app!',
            buttonTitle: 'Update'
        }).then(() => {
            window.open(vInfo.url, '_blank');
            App.exitApp();
        });
    } else if (checkVersion(appInfo.version, vInfo.latest) === -1) {
        // User can choose to update
        Dialog.confirm({
            title: 'update available!',
            message: 'Do you want to update?',
            okButtonTitle: 'Update'
        }).then((response) => {
            if (response.value) {
                window.open(vInfo.url, '_blank');
                App.exitApp();
            }
        })
    }
}

function checkVersion(a, b) {
    const x = a.split('.').map(e => parseInt(e, 10));
    const y = b.split('.').map(e => parseInt(e, 10));

    for (const i in x) {
        y[i] = y[i] || 0;
        if (x[i] === y[i]) {
            continue;
        } else if (x[i] > y[i]) {
            return 1;
        } else {
            return -1;
        }
    }
    return y.length > x.length ? -1 : 0;
}


