<template>
  <ion-menu content-id="main-content" type="overlay">
    <ion-content>
      <ion-list class="menu-list">
        <ion-list-header>
          <ion-img src="assets/img/menu-header.png"></ion-img>
        </ion-list-header>

        <ion-menu-toggle auto-hide="false" v-for="(page, i) in appPages" :key="i">
          <ion-item @click="openUrl(page.url, page.external)" lines="none" detail="false"
                    class="hydrated" :class="{ selected: this.selectedPage === page.url }">
            <ion-icon slot="start" :icon="page.icon"></ion-icon>
            <ion-label>{{ page.title }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>

      <ion-list class="menu-list">
        <ion-list-header>SOCIAL MEDIA</ion-list-header>

        <ion-menu-toggle auto-hide="false" v-for="(social, i) in socialMedia" :key="i">
          <ion-item @click="openUrl(social.url, social.external)" lines="none" detail="false"
                    class="hydrated" :class="{ selected: this.selectedPage === social.url  }">
            <ion-icon slot="start" :icon="social.icon"></ion-icon>
            <ion-label>{{ social.title }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>

      <ion-list class="menu-list">
        <ion-list-header>INFORMAȚII UTILE</ion-list-header>

        <ion-menu-toggle auto-hide="false" v-for="(info, i) in information" :key="i">
          <ion-item @click="openUrl(info.url, info.external)" lines="none" detail="false"
                    class="hydrated" :class="{ selected: this.selectedPage === info.url }">
            <ion-icon slot="start" :icon="info.icon"></ion-icon>
            <ion-label>{{ info.title }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>

import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonImg,
} from "@ionic/vue";
import {
  chatbubbleEllipsesSharp,
  chatbubblesSharp,
  clipboardSharp,
  globeSharp,
  informationCircleSharp,
  logoFacebook,
  logoInstagram,
  logoTiktok,
  logoWhatsapp,
  playSharp,
  starSharp
} from "ionicons/icons";

export default {
  components: {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonImg,
  },
  props: {
    marketUrl: null
  },
  setup(props) {
    const appPages = [
      {
        title: 'Distracție Online',
        url: '/distractie-online',
        icon: playSharp,
        external: false,
      },
      {
        title: 'Chat & Dedicații',
        url: '/tab/chat',
        icon: chatbubblesSharp,
        external: false,
      },
      {
        title: 'Site Oficial',
        url: 'https://distractieonline.ro/',
        icon: globeSharp,
        external: true,
      }
    ];
    const socialMedia = [
      {
        title: 'Facebook',
        url: 'https://www.facebook.com/distractieonline.ro/',
        icon: logoFacebook,
        external: true,
      },
      {
        title: 'Instagram',
        url: 'https://www.instagram.com/cristiamoagdei/',
        icon: logoInstagram,
        external: true,
      },
      {
        title: 'TikTok',
        url: 'https://www.tiktok.com/@cristi.amoagdei',
        icon: logoTiktok,
        external: true,
      },
      {
        title: 'WhatsApp',
        url: 'https://wa.me/34690872979',
        icon: logoWhatsapp,
        external: true,
      },
      {
        title: 'Messenger',
        url: 'http://m.me/distractieonline.ro',
        icon: chatbubbleEllipsesSharp,
        external: true,
      },
    ]
    const information = [
      {
        title: 'Evaluează aplicația',
        url: props.marketUrl,
        icon: starSharp,
        external: true,
      },
      {
        title: 'Despre aplicație',
        url: '/despre-aplicatie',
        icon: informationCircleSharp,
        external: false,
      }, {
        title: 'Confidențialitate',
        url: '/disclaimer',
        icon: clipboardSharp,
        external: false,
      },
    ]

    return {
      appPages,
      socialMedia,
      information,
    }
  },
  data() {
    return{
      selectedPage: this.appPages[0].url,
    }
  },
  methods: {
    openUrl(url, external) {
      if (external) {
        window.open(url, '_blank');
      } else {
        this.selectedPage = url;
        this.$router.replace(url)
      }
    }
  }
};
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-bottom: 20px;
}

ion-menu ion-list {
  padding: 10px 0;
}

ion-menu.md ion-list-header:not(:first-of-type) {
  padding-left: 10px;
}

ion-menu.md ion-list.menu-list ion-list-header {
  font-size: 16px;
  font-weight: 600;
  min-height: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
  color: #3880ff;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list-header {
  padding-left: 16px;
  padding-right: 16px;
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
