<template>
  <ion-app>
    <ion-split-pane content-id="main-content">
      <main-menu :market-url="this.$store.getters.getMarketUrl"></main-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  useBackButton
} from '@ionic/vue';
import MainMenu from "./components/menu/MainMenu";
import {App} from "@capacitor/app";
import {Dialog} from "@capacitor/dialog";

export default {
  components: {
    MainMenu,
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
  },
  created() {
    console.log('Created: ');
    useBackButton(-1, () => {
      const homePage = this.$router.getRoutes()[0].redirect;
      if (this.$router.currentRoute.value.path === homePage) {
        Dialog.confirm({
          title: 'Inchide aplicatia',
          message: 'Vreti sa incideti muzica?',
          okButtonTitle: 'Da',
          cancelButtonTitle: 'Nu'
        }).then((response) => {
          if (response.value) {
            App.exitApp();
          }
        })
      }else{
        this.$router.replace(homePage);
      }
    });
  }
};
</script>