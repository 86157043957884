import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [
  {
    path: '',
    redirect: '/distractie-online'
  },
  {
    path: '/distractie-online',
    component: () => import ('../views/Home.vue')
  },
  {
    path: '/tab/',
    component: () => import ('../views/chat/Chat.vue'),
    children: [
      {
        path: '',
        redirect: '/tab/chat'
      },
      {
        path: 'chat',
        component: () => import ('../views/chat/tabs/Chat.vue')
      },
      {
        path: 'dedicatie',
        component: () => import ('../views/chat/tabs/Requests.vue')
      },
    ]
  },
  {
    path: '/despre-aplicatie',
    component: () => import ('../views/InfoApp.vue')
  },
  {
    path: '/disclaimer',
    component: () => import ('../views/Disclaimer.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
