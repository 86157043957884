import {createStore} from 'vuex';
import {Capacitor} from "@capacitor/core";

const store = createStore({
    state() {
        return {
            audioStream: null,
            vInfo: {
                ios: {
                    url: "http://example.com/myAppUpdate"
                },
                android: {
                    url: "market://details?id=com.distractieonline.app"
                },
                default: {
                    url: "https://distractieonline.ro"
                    // url: 'https://play.google.com/store/apps/details?id=com.distractieonline.app',
                }
            }
        }
    },
    getters: {
        loadStream(state) {
            return state.audioStream
        },
        getMarketUrl(state) {
            if (Capacitor.isNativePlatform()) {
                const platform = Capacitor.getPlatform();
                if (platform === 'android') {
                    return state.vInfo.android.url;
                } else if (platform === 'ios') {
                    return state.vInfo.ios.url;
                }
            }
            return state.vInfo.default.url;

        }
    },
    actions: {
        loadAudioOnInput(context, audio) {
            context.commit('storeAudio', audio);
        },
        updateVInfo(context, vInfo) {
            context.commit('storeVInfo', vInfo);
        }
    },
    mutations: {
        storeAudio(state, audio) {
            state.audioStream = audio;
        },
        storeVInfo(state, vInfo) {
            state.vInfo.ios = vInfo.ios;
            state.vInfo.android = vInfo.android;
        }
    },
})
export default store;