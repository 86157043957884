import {Capacitor} from "@capacitor/core";

export function initNotifications() {
    if (Capacitor.isNativePlatform()) {
        // Add One Signal for push notifications
        document.addEventListener("deviceready", OneSignalInit, false);
    }

}

// Call this function when your app start
function OneSignalInit() {
    window["plugins"].OneSignal.setAppId("8af532eb-86a2-42bd-8fba-d2f6fc7c5cac");
    window["plugins"].OneSignal.setNotificationOpenedHandler(function (jsonData) {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });

    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
        console.log("User accepted notifications: " + accepted);
    });
}